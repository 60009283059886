<template>
  <div>
    <div class="content-col">
      <dynamic-template :name="mainTemplate"></dynamic-template>
    </div>
    <div class="right-sticky">
      <dynamic-template name="Logo"></dynamic-template>
      <dynamic-template name="Weather3"></dynamic-template>
      <dynamic-template name="Currency3"></dynamic-template>
    </div>
    <dynamic-template name="FooterBar"></dynamic-template>
  </div>
</template>

<script>
import DynamicTemplate from '../components/DynamicTemplate';

export default {
  name: 'layout7',
  props: ['data'],
  components: { DynamicTemplate },
  computed: {
    mainTemplate() {
      return this.$store.state.mainTemplate;
    }
  }
};
</script>

<style lang="scss">
.right-sticky {
  position: fixed;
  top: 0;
  right: 0;
  background: url('/images/parts/right-sticky-bg-blue.png') right top no-repeat;
  background-size: 100%;
  width: 247px;
  height: 100%;
  z-index: 5;
  padding-right: 10px;

  .logo {
    margin: 30px 0;
    text-align: right;
    img {
      width: 222px;
      height: 164px;
      -o-object-fit: contain;
      object-fit: contain;
      object-position: center;
    }
  }
  .daily-info {
    &.three {
      margin-top: 54px;
      .item {
        margin-bottom: 54px;
      }
    }
  }
}
.content-col {
  width: calc(100% - 150px);
  overflow: hidden;
  position: absolute;
  height: 100%;
  left: 0;
}
.pharmacy {
  .main-content {
    padding-right: 60px;
    .item {
      max-width: 746px;
      padding: 14px 60px;
    }
    .detail {
      min-width: 576px;
    }
  }
}
</style>
